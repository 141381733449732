import React from 'react';
import axios from 'axios';
import Menu from './Menu';

const RunRentDue = () => {
  const runRentDue = async () => {
    try {
      const response = await axios.get('https://alibizness.com/api_rent/rent_due.php');
      alert('Rent due entries created successfully');
    } catch (error) {
      console.error('Error running rent due script', error);
      alert('Error running rent due script');
    }
  };

  return (
    <div className="container">
        <Menu />

        <br />
        <br />
      <button className="btn btn-primary" onClick={runRentDue}>Click to Run Rent Due</button>
    </div>
  );
};

export default RunRentDue;

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from './UserContext';

const Login = () => {
  const [username, setUsername] = useState('admin');
  const [password, setPassword] = useState('123');
  const navigate = useNavigate();
  const { setUsername: setContextUsername } = useContext(UserContext);

  const handleLogin = () => {
    fetch('https://alibizness.com/api_rent/login.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          // Login successful
          setContextUsername(username); // Set the username in context
          navigate('/ledger');
        } else {
          // Login failed
          alert('Login Failed: ' + json.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="container">
      <style>
        {`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100vh;
          background-color: #e0e7ff; /* Light blue background */
        }

        .logoImage {
          width: 400px;
          margin-bottom: 20px;
        }

        .inputContainer {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          position: relative; /* Add relative positioning */
          width: 100%;
          max-width: 400px; /* Limit input width */
        }

        .icon {
          position: absolute; /* Position icon inside the input */
          left: 10px;
          color: #007bff; /* Blue color for the icon */
        }

        .input {
          width: 100%;
          padding: 10px 10px 10px 40px; /* Add padding to the left for the icon */
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 16px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add shadow for better look */
        }

        .input:focus {
          border-color: #007bff; /* Blue border on focus */
          outline: none; /* Remove default outline */
        }

        .loginButton {
          padding: 10px 20px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.3s ease; /* Smooth transition */
        }

        .loginButton:hover {
          background-color: #0056b3; /* Darker blue on hover */
        }
        `}
      </style>
      <img 
        src="" 
        alt="Logo"
        className="logoImage"
      />
      <div className="inputContainer">
        <FontAwesomeIcon icon={faUser} size="lg" className="icon" />
        <input
          type="text"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          placeholder="Username"
          className="input"
        />
      </div>
      <div className="inputContainer">
        <FontAwesomeIcon icon={faLock} size="lg" className="icon" />
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="Password"
          className="input"
        />
      </div>
      <button onClick={handleLogin} className="loginButton">Login</button>
    </div>
  );
};

export default Login;

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Menu from './Menu';
import { Modal, Button, Form } from 'react-bootstrap';

const LedgerSingle = () => {
  const { id } = useParams();
  const [ledger, setLedger] = useState([]);
  const [shopName, setShopName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [formData, setFormData] = useState({
    date: '',
    particulars: '',
    amount: ''
  });
  const printRef = useRef();

  useEffect(() => {
    fetchLedger();
    fetchShopName();
  }, [id]);

  const fetchLedger = async () => {
    try {
      const response = await fetch(`https://alibizness.com/api_rent/get_ledger_single.php?shop_id=${id}`);
      const data = await response.json();
      setLedger(data);
    } catch (error) {
      console.error('Error fetching ledger:', error);
    }
  };

  const fetchShopName = async () => {
    try {
      const response = await fetch(`https://alibizness.com/api_rent/get_shop_name.php?shop_id=${id}`);
      const data = await response.json();
      setShopName(data.shop_name);
    } catch (error) {
      console.error('Error fetching shop name:', error);
    }
  };

  const handleShowModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      date: '',
      particulars: '',
      amount: ''
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSave = async () => {
    try {
      const response = await fetch('https://alibizness.com/api_rent/create_ledger_entry.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          shop_id: id,
          date: formData.date,
          particulars: formData.particulars,
          amount: formData.amount,
          type: modalType
        }),
      });
      const data = await response.json();
      if (data.success) {
        fetchLedger();
        handleCloseModal();
      } else {
        console.error('Error saving entry:', data.error);
      }
    } catch (error) {
      console.error('Error saving entry:', error);
    }
  };

  const calculateBalance = () => {
    let receivedTotal = 0;
    let paidTotal = 0;

    ledger.forEach(item => {
      receivedTotal += parseFloat(item.recieved);
      paidTotal += parseFloat(item.paid);
    });

    return receivedTotal - paidTotal;
  };

  const printInvoice = (item) => {
    const printContent = `
      <style>
        .invoice-container {
          font-family: Arial, sans-serif;
          width: 80%;
          margin: auto;
          border: 1px solid #ddd;
          padding: 20px;
        }
        .invoice-header {
          text-align: center;
          margin-bottom: 20px;
        }
        .invoice-header h2 {
          margin: 0;
          font-size: 24px;
        }
        .invoice-details {
          margin-bottom: 20px;
        }
        .invoice-details p {
          margin: 5px 0;
        }
        .invoice-table {
          width: 100%;
          border-collapse: collapse;
        }
        .invoice-table th, .invoice-table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
        .invoice-table th {
          background-color: #f2f2f2;
        }
        .invoice-footer {
          text-align: center;
          margin-top: 20px;
        }
      </style>
      <div class="invoice-container">
        <div class="invoice-header">
          <h2>${shopName} - Invoice</h2>
        </div>
        <div class="invoice-details">
          <p><strong>Date:</strong> ${moment(item.ledger_date).format('DD MMM YYYY')}</p>
          <p><strong>Particulars:</strong> ${item.description}</p>
          <p><strong>Received:</strong> ${item.recieved}</p>
          <p><strong>Paid:</strong> ${item.paid}</p>
        </div>
        <table class="invoice-table">
          <thead>
            <tr>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${item.description}</td>
              <td>${item.recieved || item.paid}</td>
            </tr>
          </tbody>
        </table>
        <div class="invoice-footer">
          <p>Thank you for your Payment!</p>
        </div>
      </div>
    `;
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Invoice</title></head><body>');
    printWindow.document.write(printContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const printLedger = () => {
    const printContent = `
      <div style="font-family: Arial, sans-serif; padding: 20px;">
        <h1 style="text-align: center;">${shopName} - Ledger Details</h1>
        ${printRef.current.innerHTML}
      </div>
    `;
    const originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload();  // Reload the page to restore the original content
  };

  const sendOnWhatsApp = () => {
    const ledgerText = ledger.map(item => 
      `Date: ${moment(item.ledger_date).format('DD MMM YYYY')}, Particulars: ${item.description}, Received: ${item.recieved}, Paid: ${item.paid}`
    ).join('\n');
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(ledgerText)}`;
    window.open(whatsappUrl, '_blank');
  };

  const balance = calculateBalance();

  return (
    <div className="container">
      <Menu />

      <Button variant="primary" onClick={() => handleShowModal('received')}>Received</Button>
      <Button variant="danger" onClick={() => handleShowModal('paid')}>Paid</Button>
      <Button variant="success" onClick={printLedger}>Print</Button>
      <Button variant="danger" onClick={sendOnWhatsApp}>WhatsApp</Button>
      
      <div ref={printRef}>
        <h1>{shopName} - Ledger Details</h1>
        <table className="table table-striped table-hover text-center">
          <thead className="table-dark">
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Received</th>
              <th>Paid</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {ledger.map((item) => (
              <tr key={item.ledger_id}>
                <td>{moment(item.ledger_date).format('ddd, DD MMM YYYY')}</td>
                <td>{item.description}</td>
                <td>{item.recieved}</td>
                <td>{item.paid}</td>
                <td>
                  <Button variant="primary" onClick={() => printInvoice(item)}>Print Invoice</Button>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="4"><strong>Balance</strong></td>
              <td>{balance}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalType === 'received' ? 'Received' : 'Paid'} Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDate">
              <Form.Label>Date</Form.Label>
              <Form.Control type="date" name="date" value={formData.date} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group controlId="formParticulars">
              <Form.Label>Particulars</Form.Label>
              <Form.Control type="text" name="particulars" value={formData.particulars} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="number" name="amount" value={formData.amount} onChange={handleInputChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LedgerSingle;

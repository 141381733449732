import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Menu from './Menu';
import 'bootstrap/dist/css/bootstrap.min.css';

const Shops = () => {
  const [shops, setShops] = useState([]);
  const [shopName, setShopName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [security, setSecurity] = useState('');
  const [rentAmount, setRentAmount] = useState('');
  const [document, setDocument] = useState(null);
  const [editShopId, setEditShopId] = useState(null);

  useEffect(() => {
    fetchShops();
  }, []);

  const fetchShops = async () => {
    const response = await axios.get('https://alibizness.com/api_rent/read_shops.php');
    setShops(response.data);
  };

  const createShop = async () => {
    const formData = new FormData();
    formData.append('shop_name', shopName);
    formData.append('phone_number', phoneNumber);
    formData.append('security', security);
    formData.append('rent_amount', rentAmount);
    formData.append('document', document);

    await axios.post('https://alibizness.com/api_rent/create_shop.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    setShopName('');
    setPhoneNumber('');
    setSecurity('');
    setRentAmount('');
    setDocument(null);
    fetchShops();
  };

  const updateShop = async () => {
    const formData = new FormData();
    formData.append('shop_id', editShopId);
    formData.append('shop_name', shopName);
    formData.append('phone_number', phoneNumber);
    formData.append('security', security);
    formData.append('rent_amount', rentAmount);
    if (document) {
      formData.append('document', document);
    }

    await axios.post('https://alibizness.com/api_rent/update_shop.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    setShopName('');
    setPhoneNumber('');
    setSecurity('');
    setRentAmount('');
    setDocument(null);
    setEditShopId(null);
    fetchShops();
  };

  const deleteShop = async (shopId) => {
    await axios.delete('https://alibizness.com/api_rent/delete_shop.php', { data: { shop_id: shopId } });
    fetchShops();
  };

  return (
    <div className="container">
      <Menu />
      <h1 className="mt-4 mb-4">Shops</h1>
      <div className="mb-3">
        <input
          type="text"
          className="form-control mb-2"
          value={shopName}
          onChange={(e) => setShopName(e.target.value)}
          placeholder="Shop Name"
        />
        <input
          type="text"
          className="form-control mb-2"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Phone Number"
        />
        <input
          type="text"
          className="form-control mb-2"
          value={security}
          onChange={(e) => setSecurity(e.target.value)}
          placeholder="Security"
        />
        <input
          type="text"
          className="form-control mb-2"
          value={rentAmount}
          onChange={(e) => setRentAmount(e.target.value)}
          placeholder="Rent Amount"
        />
        <input
          type="file"
          className="form-control mb-2"
          onChange={(e) => setDocument(e.target.files[0])}
        />
        {editShopId ? (
          <button className="btn btn-primary" onClick={updateShop}>Update Shop</button>
        ) : (
          <button className="btn btn-success" onClick={createShop}>Create Shop</button>
        )}
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Shop ID</th>
            <th>Shop Name</th>
            <th>Phone Number</th>
            <th>Security</th>
            <th>Rent Amount</th>
            <th>Document</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {shops.map((shop) => (
            <tr key={shop.shop_id}>
              <td>{shop.shop_id}</td>
              <td>{shop.shop_name}</td>
              <td>{shop.phone_number}</td>
              <td>{shop.security}</td>
              <td>{shop.rent_amount}</td>
              <td>
                {shop.document && (
                  <a href={`https://alibizness.com/api_rent/${shop.document}`} target="_blank" rel="noopener noreferrer">View Document</a>
                )}
              </td>
              <td>
                <button className="btn btn-warning mr-2" onClick={() => { 
                  setShopName(shop.shop_name); 
                  setPhoneNumber(shop.phone_number);
                  setSecurity(shop.security);
                  setRentAmount(shop.rent_amount);
                  setEditShopId(shop.shop_id); 
                }}>Edit</button>
                <button className="btn btn-danger" onClick={() => deleteShop(shop.shop_id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Shops;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMoneyBill, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import './Ledger.css';
import Menu from './Menu'

const Ledger = () => {
  const [query, setQuery] = useState('');
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [customerName, setCustomerName] = useState('');

  const navigate = useNavigate();

  const fetchCustomers = async () => {
    try {
      const response = await fetch('https://alibizness.com/api_rent/get_balance.php');
      const data = await response.json();
      setCustomers(data);
      setFilteredCustomers(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const deleteCustomer = async (customerId) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      try {
        const response = await fetch('https://alibizness.com/api_rent/delete_balance.php', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ shop_id: customerId }),
        });
        const data = await response.json();
        if (data.success) {
          fetchCustomers(); // Refresh customers after deleting
        } else {
          console.error('Failed to delete customer');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCustomerNameClick = (id) => {
    navigate(`/ledger-single/${id}`);
  };

  const getInitials = (name) => {
    return name.split(' ').map(word => word.charAt(0)).join('').substring(0, 2).toUpperCase();
  };

  const colors = ['#007bff', '#6c757d', '#28a745', '#dc3545', '#ffc107', '#17a2b8', '#f8f9fa'];

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setQuery(searchValue);
    const filtered = customers.filter(customer =>
      customer.shop_name.toLowerCase().includes(searchValue)
    );
    setFilteredCustomers(filtered);
  };

  return (
    <div className="container">
      <Menu />
      <div className="input-container position-relative my-3">
        <input
          type="text"
          placeholder="Search Shop..."
          value={query}
          onChange={handleSearch}
          className="search-input form-control"
        />
        <FontAwesomeIcon icon={faSearch} size="lg" color="gray" className="search-icon" />
      </div>

      <table className="table table-striped table-hover text-center">
        <thead className="table-dark">
          <tr>
            <th>Serial No</th>
            <th>Initials</th>
            <th>Shop</th>
            <th>Balance</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredCustomers.map((item, index) => (
            <tr key={item.shop_id}>
              <td>
                <div className="circle" style={{ backgroundColor: colors[index % colors.length] }}>
                  <span className="circle-text">{index + 1}</span>
                </div>
              </td>
              <td>
                <div className="circle" style={{ backgroundColor: colors[index % colors.length] }}>
                  <span className="circle-text">{getInitials(item.shop_name)}</span>
                </div>
              </td>
              <td>
                <button onClick={() => handleCustomerNameClick(item.shop_id)} className="btn btn-link text-decoration-none text-dark">
                  {item.shop_name}
                </button>
              </td>
              <td style={{ color: item.tot_bal > 0 ? '#4CAF50' : '#F44336' }}>
                <b>Rs {Math.abs(item.tot_bal).toLocaleString('en-US', { minimumFractionDigits: 2 })}</b>
              </td>
              <td>{moment(item.ledger_date).format('ddd, DD MMM YYYY')}</td>
              <td>
                <Button onClick={() => deleteCustomer(item.shop_id)} className="btn btn-danger me-2">
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Ledger;

import React, { useState } from 'react';
import axios from 'axios';
import Menu from './Menu';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Report.css'; // Import the custom CSS

const Report = () => {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [data, setData] = useState([]);
    const [error, setError] = useState('');

    const handleSearch = async () => {
        try {
            const response = await axios.get('https://alibizness.com/api_rent/give_report.php', {
                params: {
                    from_date: fromDate,
                    to_date: toDate
                }
            });
            setData(response.data);
        } catch (err) {
            setError('Error fetching data');
            console.error(err);
        }
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <div>
            <Menu />
            <h1>Ledger Report</h1>
            <div className="search-section">
                <label>
                    From Date:
                    <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                </label>
                <label>
                    To Date:
                    <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                </label>
                <button className='btn btn-success' onClick={handleSearch}>Search</button>
                <button className='btn btn-danger' onClick={handlePrint}>Print</button>
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <table className='table table-bordered printable'>
                <thead>
                    <tr>
                        <th>Serial#</th>
                        <th>Month</th>
                        <th>Shop Name</th>
                        <th>Rent/Month</th>
                        <th>Paid</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((record, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{record.Month_name}</td>
                            <td>{record.shop_name}</td>
                            <td>{record.rent_amount}</td>
                            <td>{record.tot_recieved}</td>
                            <td>{record.rent_amount - record.tot_recieved}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Report;

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Menu.css'; // Import the custom CSS

const Menu = () => {
    const navigate = useNavigate();
    const { username } = useContext(UserContext);

    const handleLogout = () => {
        navigate('/');
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">Admin Section</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <button className="nav-link btn btn-link" onClick={() => navigate('/Ledger')}>Ledger</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link btn btn-link" onClick={() => navigate('/shops')}>Shops</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link btn btn-link" onClick={() => navigate('/rent-due')}>Rent Due</button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link btn btn-link" onClick={() => navigate('/report')}>Report</button>
                        </li>
                    </ul>
                    <div className="d-flex align-items-center">
                        {username && <span className="navbar-text text-white me-3">{username}</span>}
                        <button onClick={handleLogout} className="btn btn-outline-light">Logout</button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Menu;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Ledger from './Ledger';
import LedgerSingle from './LedgerSingle'
import Shops from './Shops'
import RunRentDue from './RunRentDue';
import { UserProvider } from './UserContext';
import Report from './Report';

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="ledger" element={<Ledger />} />
          <Route path="shops" element={<Shops />} />
          <Route path="rent-due" element={<RunRentDue />} />
          <Route path="/ledger-single/:id" element={<LedgerSingle />} />
          <Route path="/report" element={<Report />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
